import React from 'react';
import {
  CodeSnippet,
  Link,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from 'docComponents';
import { Text } from 'hudl-uniform-ui-components';
import { SearchInput } from 'hudl-rn-uniform-ui';

const snippet = require('raw-loader!../../../../../data/snippets/rn-search-input.example');

const ReactNativeTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{ React: React, SearchInput: SearchInput }}
        code={snippet}
        platform="react-native"
        gitHubLink="forms/search-input"
        disableCodeEditing
      />

      <Section title="Props">
        <PropList>
          <PropListItem name="size" types={['oneOf', 'string']}>
            <Text>Determines the size of the input.</Text>
            <List type="unordered">
              <li>
                <code>xsmall</code>
              </li>
              <li>
                <code>small</code>
              </li>
              <li>
                <code>medium</code> (default)
              </li>
              <li>
                <code>large</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="disabled" types={['bool']}>
            <Text>Determines whether the input is disabled.</Text>
          </PropListItem>

          <PropListItem name="hasError" types={['bool']}>
            <Text>
              Determine whether to show an error state (red text and border) on
              the input.
            </Text>
          </PropListItem>

          <PropListItem name="space" types={['oneOf', 'string']}>
            <Text>
              Any of the{' '}
              <Link href="/resources/code-variables/space?reactnative">
                space variables
              </Link>
              .
            </Text>
          </PropListItem>

          <PropListItem name="testID" types={['string']}>
            <Text>
              A unique string to control this input in automated tests.
            </Text>
          </PropListItem>

          <PropListItem name="accessibilityLabel" types={['string']}>
            <Text>
              Overrides the text that's read by the screen reader when the user interacts with the input.
            </Text>
          </PropListItem>

          <PropListItem name="theme" types={['oneOf', 'string']}>
            <Text>Determines the theme of the input.</Text>
            <List type="unordered">
              <li>
                <code>light</code> (default)
              </li>
              <li>
                <code>dark</code>
              </li>
            </List>
          </PropListItem>
        </PropList>
      </Section>

      <Section title="Notes">
        <List type="unordered">
          <li>
            You can pass in any of the other input props from{' '}
            <Link href="https://facebook.github.io/react-native/docs/textinput.html">React Native's TextInput</Link>.
          </li>
        </List>
      </Section>
    </PlatformTab>
  );
};

export default ReactNativeTab;
