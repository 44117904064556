import React from 'react';
import {
  CodeSnippet,
  Link,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from 'docComponents';
import { Text, SearchInput } from 'hudl-uniform-ui-components';

const snippet = require('raw-loader!../../../../../data/snippets/search-input.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{ React: React, SearchInput: SearchInput }}
        code={snippet}
        platform="react"
        gitHubLink="forms/search-input"
      />

      <Section title="Props">
        <PropList>
          <PropListItem name="size" types={['string']}>
            <Text>The size of the search input you want.</Text>
            <List type="unordered">
              <li>
                <code>xsmall</code>
              </li>
              <li>
                <code>small</code>
              </li>
              <li>
                <code>medium</code>
              </li>
              <li>
                <code>large</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="name" types={['string']}>
            <Text>
              The name for the input. Passed to the <code>id</code> of the input
              and the <code>for</code> attribute of the label to bind them
              together.
            </Text>
          </PropListItem>

          <PropListItem name="disabled" types={['bool']}>
            <Text>Determines whether the input is disabled.</Text>
          </PropListItem>

          <PropListItem name="hasError" types={['bool']}>
            <Text>
              Determine whether to show an error state (red text and border) on
              the input.
            </Text>
          </PropListItem>

          <PropListItem name="className" types={['string']}>
            <Text>Add a custom class to the form element.</Text>
          </PropListItem>

          <PropListItem name="inputRef" types={['func']}>
            <Text>A ref passed to the native input element.</Text>
          </PropListItem>

          <PropListItem name="qaId" types={['string']}>
            <Text>
              A unique-enough string that appears as a data attribute in the
              rendered code and serves as a hook for automated tests.
            </Text>
          </PropListItem>

          <PropListItem name="onDismissClick" types={['func']}>
            <Text>
              Responds to the dismiss being clicked.
              Here is where you'd clear out the value of the search input.
            </Text>
          </PropListItem>
        </PropList>
      </Section>

      <Section title="Notes">
        <List type="unordered">
          <li>
            If you pass in a <code>value</code> prop, be sure you also pass in
            an <code>onChange</code> prop so the{' '}
            <Link href="https://reactjs.org/docs/forms.html#controlled-components">
              component will update
            </Link>{' '}
            when the user types.
          </li>
          <li>
            Native input props can be passed in such as <code>onChange</code>,{' '}
            <code>default</code>, <code>max</code>, etc.
          </li>
          <li>
            Since the <code>name</code> attribute is passed to the{' '}
            <code>input</code>
            ’s <code>id</code>, make sure you provide a unique name.
          </li>
        </List>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
